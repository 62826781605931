<template>
  <el-col class="container">
    <breadcrumb></breadcrumb>
    <el-row class="container__overflow" v-if="overflow">
      <el-col class="col">
        <el-row class="container__row">
          <slot></slot>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="container__row" v-else>
      <slot></slot>
    </el-row>
  </el-col>
</template>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__overflow {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .col {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
  }

  .container__row {
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container__row {
    max-width: 720px;
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .container__row {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container__row {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container__row {
    max-width: 1340px;
  }
}
</style>

<script>
import Breadcrumb from './components/Breadcrumb'

export default {
  components: {
    Breadcrumb
  },
  props: {
    title: String,
    subtitle: String,
    overflow: Boolean
  }
}
</script>
