<template>
  <div class="form" :style="{ width: width ? width + 'px' : '', maxWidth:  width ? width + 'px' : '' }">
    <div class="form__alert" v-if="$slots['alert']">
      <slot name="alert"></slot>
    </div>
    <div class="form__content">
      <slot></slot>
    </div>
    <div class="form__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/components/_variables.bootstrap.scss';

.form {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  width: 100%;

  &__alert {
    margin-bottom: 20px;
  }

  &__footer {
    text-align: right;

    & > button {
      margin-left: 10px;
    }
  }
}
</style>

<script>
export default {
  props: {
    width: Number
  }
}
</script>
