<template>
  <gf-card style="height: 455px" :title="title" :title-background="type" background="bg-gray-100" :body-style="'padding: 0;'">
    <apexcharts class="pb-10" :class="'bg-' + type" :height="150" :options="options" :series="series"></apexcharts>
    <div class="body" >
      <div class="content mt-n15">
        <div class="content__row mb-5">
          <div class="stat mr-5">
            <slot name="box-1-1"></slot>
          </div>
          <div class="stat">
            <slot name="box-1-2"></slot>
          </div>
        </div>
        <div class="content__row">
          <div class="stat mr-5">
            <slot name="box-2-1"></slot>
          </div>
          <div class="stat">
            <slot name="box-2-2"></slot>
          </div>
        </div>
      </div>
    </div>
  </gf-card>
</template>

<style lang="scss" scoped>
.graph {
  z-index: 0;
}
.body {
  z-index: 99;
  height: 400px;
  height: 100%;

  .content {
    &__row {
      z-index: 100;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .stat {
        height: 115px;
        width: 150px;
        background: #fff;
        border-radius: 20px;
      }
    }
  }
}
</style>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  props: {
    title: String,
    type: {
      type: String,
      default: 'primary'
    }
  },
  components: {
    apexcharts: VueApexCharts
  },
  data () {
    return {
      solidColor: {
        primary: '#3699FF',
        secondary: '#E4E6EF',
        success: '#1BC5BD',
        info: '#8950FC',
        warning: '#FFA800',
        danger: '#F64E60'
      },
      lightColor: {
        primary: '#E1F0FF',
        secondary: '#EBEDF3',
        success: '#C9F7F5',
        info: '#EEE5FF',
        warning: '#FFF4DE',
        danger: '#FFE2E5'
      },
      darkColor: {
        primary: '#E1F0FF',
        secondary: '#EBEDF3',
        success: '#159f97',
        info: '#EEE5FF',
        warning: '#FFF4DE',
        danger: '#FFE2E5'
      },
      series: [{
        name: 'series-1',
        data: [30, 45, 32, 70, 40, 40, 40]
      }],
      options: {
        chart: {
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 5,
            left: 0,
            blur: 3,
            opacity: 0.5,
            color: ''
          },
          fontFamily: 'Poppins, Helvetica, sans-serif'
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opactiy: 0
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: []
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          },
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          hover: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0
            }
          }
        },
        tooltip: {
          enabled: false
        },
        colors: ['transparent']
      }
    }
  },
  created () {
    this.options.chart.dropShadow.color = this.darkColor[this.type]
    this.options.stroke.colors.push(this.darkColor[this.type])
  }
}
</script>
