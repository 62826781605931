<template>
  <gf-card :title="title" :subtitle="subtitle" style="height: 500px">
      <div class="mini-widget__list mb-5" v-for="(item, index) in items" :key="item.id" @click="rowClick(index)">
        <div class="mini-widget__list__content pb-1 pt-1">
          <div style="display: flex; align-items: center">
            <span class="bullet bullet--bar align-self-stretch ml-2 mr-2" :class="'bg-' + item.type"></span>
            <div class="mini-widget__list__content__item">
              <span class="font-size-sm font-weight-bold text-dark" :class="[light ? 'text-dark' : 'text-white']">{{ item.header }}</span>
              <span class="font-size-xs font-weight-bold text-muted" :class="[light ? 'text-muted' : 'text-white']">{{ item.subheader }}</span>
            </div>
          </div>
          <gf-label v-if="item.status" inline pill light :type="item.type"><span class="font-size-xs font-weight-normal">{{ item.status }}</span></gf-label>
          <span v-else class="font-size-sm font-weight-bolder pr-4" :class="[light ? 'text-' + item.type : 'text-white']">{{ item.quantity }}</span>
        </div>
      </div>
  </gf-card>
</template>

<style lang="scss" scoped>
.mini-widget__list {
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
</style>

<script>
export default {
  data () {
    return {
      color: ''
    }
  },
  props: {
    items: Array,
    title: String,
    subtitle: String,
    light: Boolean
  },
  methods: {
    rowClick (item) {
      this.$emit('row-click', item)
    }
  }
}
</script>
