<template>
  <div class="alert" :class="type ? 'alert--' + type : ''">
    <div class="alert__icon" v-if="$slots['icon']">
      <slot name="icon"></slot>
    </div>
    <div class="alert__content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/components/_variables.bootstrap.scss';

.alert {
  background: $gray-100;
  color: $gray-800;
  font-weight: $font-weight-normal;
  padding: 20px;
  border-radius: 7.5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__content {
    margin-left: 10px;
    font-size: 13px;
    word-break: keep-all;
  }
}
</style>

<script>
export default {
  props: {
    type: String
  }
}
</script>
