<template>
  <el-card class="widget" :class="[background]" :body-style="{ padding: '0px', border: 'none' }">
    <div class="icon-container">
      <span class="svg-icon svg-icon-2x" :class="[light ? 'svg-icon-' + type : 'svg-icon-white']">
        <slot name="icon"></slot>
      </span>
    </div>
    <div class="mt-4 mb-1">
      <span class="font-size-h2 font-weight-bolder" :class="[light ? 'text-dark-75' : 'text-white']">{{ data }}</span>
    </div>
    <span class="font-weight-bold font-size-xs" :class="[light ? 'text-muted' : 'text-white']">{{ subtitle }}</span>
  </el-card>
</template>

<style lang="scss" scoped>
.el-card {
  border: none !important;
}

.icon-container {
  margin-left: -5px;
}

.widget {
  display: flex;
  align-items: center;
  padding: 0 25px;
  line-height: 1.2;
  height: 160px;
}
</style>

<script>
export default {
  props: {
    subtitle: String,
    data: String,
    light: Boolean,
    type: String
  },
  computed: {
    background () {
      let className = 'bg'
      if (this.light) className += '-light'
      if (this.type) className += `-${this.type}`
      return className
    }
  }
}
</script>
