<template>
  <transition name="fade">
    <div class="modal" v-if="visible">
      <div class="modal-mask" @click="toggleModal(false)"></div>
      <div class="modal-wrapper">
        <div class="modal-body">
          <div class="modal-body-header">
            <span class="title">{{ title }}</span>
            <span class="close" @click="toggleModal(false)"><i class="el-icon-close"></i></span>
          </div>
          <div class="modal-body-content">
            <slot name="content"></slot>
          </div>
          <div class="modal-body-footer">
            <button class="danger" v-if="deleteButton && !deleteLoading" @click="handleDelete"><i class="el-icon-delete"></i></button>
            <button class="danger loading" v-if="deleteButton && deleteLoading"><i class="el-icon-loading"></i></button>
            <div class="main-button">
              <button @click="toggleModal(false)" :style="!deleteButton ? 'border-bottom-left-radius: 0px': ''">Cancel</button>
              <button class="primary" @click="handleSubmit" v-if="!loading" >{{submitTitle}}</button>
              <button class="primary loading" v-else><i class="el-icon-loading"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #409EFF);
  border-radius: 1px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.modal {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &-mask {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
  }

  .modal-wrapper {
    z-index: 2;
    flex-basis: 560px;
    max-height: calc(100vh - 100px);

    .modal-body {
      // border-radius: 5px;
      background: #fff;

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        background-color: #409EFF;
        padding: 0 10px;

        .title {
          color: #fff;
          font-size: 15px;
        }

        .close {
          color: #fff;
          padding: 5px;
          transition: .3s all;
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, .1);
          }
        }
      }

      &-content {
        flex-grow: 1;
      }

      &-footer {
        height: 45px;
        display: flex;

        button {
          border: none;
          height: 100%;
          font-size: 15px;
          color: #888;
          cursor: pointer;
          transition: .3s all;

          &:focus {
            outline: none;
          }

          &:hover {
            background: rgba(0, 0, 0, .15);
          }
        }

        .primary {
          background: #409EFF;
          color: #fff;
          transition: 0.25s;

          &:hover {
            background: #3192f3;
            color: #fff;
          }
        }

        .danger {
          background: #f56c6c;
          color: rgb(240, 220, 220);
          width: 50px;
          // border-bottom-left-radius: 5px;

          &:hover {
            background: #f55a5a;
          }
        }

        .loading {
          cursor: not-allowed !important;
        }

        .main-button {
          flex-grow: 1;

          button {
            width: 50%;

            &:last-of-type {
              // border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    title: String,
    visible: Boolean,
    loading: Boolean,
    deleteButton: Boolean,
    deleteLoading: Boolean,
    submitTitle: String
  },
  methods: {
    toggleModal (visible) {
      this.$emit('update:visible', visible)
      this.$emit('closing')
    },
    handleDelete () {
      this.$emit('delete')
    },
    handleSubmit () {
      this.$emit('submit')
    }
  }
}
</script>
