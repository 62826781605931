<template>
  <el-row class="view">
    <el-col class="view__content">
      <el-row class="view__content__header">
        <el-col class="view__content__header__content">
          <span class="view__content__header__content__title">{{ title }}</span>
          <div class="variants__content__header__content__toolbar">
            <slot name="toolbar"></slot>
          </div>
        </el-col>
      </el-row>
      <el-row class="view__content__body">
        <el-col class="view__content__body__content">
          <slot></slot>
        </el-col>
      </el-row>
      <el-row class="view__content__footer" v-if="$slots['footer']">
        <slot name="footer"></slot>
      </el-row>
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
.view {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      padding: 0 20px;

      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #f4f4f4;

        &__title {
          color: #222;
          font-weight: 400;
          font-size: 14px;
        }

        &__toolbar {
          display: flex;
          align-items: center;
        }
      }
    }

    &__body {
      flex: 1;

      &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &__footer {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>

<script>
export default {
  props: {
    title: String
  }
}
</script>
