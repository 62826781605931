<template>
  <gf-card :title="title" :subtitle="subtitle" :body-style="'padding: 10px 0 0 0'">
    <template #toolbar>
      <div>
        <span class="font-weight-bold font-size-h3" :class="'text-' + type">{{ data }}</span>
      </div>
    </template>
    <apexcharts :height="200" :options="options" :series="series"></apexcharts>
  </gf-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  props: {
    title: String,
    subtitle: String,
    data: String,
    type: {
      type: String,
      default: 'primary'
    }
  },
  components: {
    apexcharts: VueApexCharts
  },
  data () {
    return {
      solidColor: {
        primary: '#3699FF',
        secondary: '#E4E6EF',
        success: '#1BC5BD',
        info: '#8950FC',
        warning: '#FFA800',
        danger: '#F64E60'
      },
      lightColor: {
        primary: '#E1F0FF',
        secondary: '#EBEDF3',
        success: '#C9F7F5',
        info: '#EEE5FF',
        warning: '#FFF4DE',
        danger: '#FFE2E5'
      },
      series: [{
        name: 'series-1',
        data: [30, 45, 32, 70, 40, 40, 40]
      }],
      options: {
        chart: {
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          },
          fontFamily: 'Poppins, Helvetica, sans-serif'
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opactiy: 1,
          colors: []
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 4
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          },
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          hover: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0
            }
          }
        },
        tooltip: {
          enabled: false
        },
        colors: []
      }
    }
  },
  created () {
    this.options.fill.colors.push(this.lightColor[this.type])
    this.options.colors.push(this.solidColor[this.type])
  }
}
</script>
