<template>
  <gf-base>
    <el-card :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: '0px', pageBreakInside: 'auto !important'}" class="base-card">
      <el-row class="body-row">
        <el-col class="col">
            <div class="options">
              <div style="display: flex; flex-direction: row">
                <gf-button :type="type" size="small" style="margin-right: 10px" @click="switchColor">Preview {{ this.text }} </gf-button>
                <gf-button :type="type" size="small" style="margin-right: 10px" @click="print">Print</gf-button>
                <gf-button :type="type" size="small" @click="back">Back</gf-button>
              </div>
            </div>
          <el-row>
            <el-col :span="24">
              <div class="water-mark">
                <img src="../assets/logo-2.png">
              </div>
            </el-col>
          </el-row>
          <el-row :class="[colorMode ? 'base-card__header--' + colorMode: '']" class="base-card__header" type="flex">
            <el-col :span="24" class="base-card__header__title">
              <el-row class="base-card__header__title__row">
                <el-col :span="24" class="base-card__header__title__row__transaction-type">
                  <slot name="title" v-bind:plain="plain"></slot>
                </el-col>
                <el-col :span="24" class="base-card__header__title__row__subcontent">
                  <slot name="Company Details" v-bind:plain="plain"></slot>
                </el-col>
              </el-row>
            </el-col>
            <el-col :class="[colorMode ? 'base-card__header__details--' + colorMode: '']" class="base-card__header__details">
              <el-row class="base-card__header__details__row">
                <slot name="Receiver" v-bind:plain="plain">
                </slot>
              </el-row>
            </el-col>
          </el-row>
          <el-row :class="[colorMode ? 'base-card__description--' + colorMode: '']" class="base-card__description" type="flex">
            <slot name="Data Table" v-bind:plain="plain"></slot>
            <div class="table-footer"><span class="span"><i>*** Nothing Follows ***</i></span></div>
          </el-row>
          <el-row :class="[colorMode ? 'base-card__breakdown--' + colorMode: '']" class="base-card__breakdown">
            <el-col :md="24" :sm="24" class="base-card__breakdown__desc">
                <slot name="Summary" v-bind:plain="plain"></slot>
            </el-col>
            <el-col :md="24" :sm="24" class="base-card__breakdown__summary">
              <el-col :md="24" :sm="24" class="base-card__breakdown__summary">
                <slot name="Total" v-bind:plain="plain"></slot>
              </el-col>
            </el-col>
          </el-row>
          <slot name="signing-area"></slot>
        </el-col>
      </el-row>
      </el-card>
  </gf-base>
</template>

<style lang="scss">
  @media print {

    .water-mark {
      margin-right: 0px !important;

      img { width: 300px !important; }
    }

    .print-text { font-size: 12.5px; }

    .span { font-size: 12.5px; }

    .breadcrumb { display: none; }
    .options { display: none; }
    .el-popover { display: none !important; }
    .el-main { top: 0 !important }
    .el-notification { display: none }

    // Outside the el-card

    body {
      overflow: initial !important;
      height: initial;
      background: #fff !important;
    }

    .el-main { overflow: initial !important; }

    .topbar { display: none; }

    .base-container {
      margin-top: -65px;
      background: #fff !important;
    }

    .base-container__row {
      margin: 0 !important;
      padding: 0 !important;
    }

    .base-container__header { display: none !important; }
    .base-container__row { margin: 0px 0px 0px 0px !important; }
    .base-container__row--space-bottom { margin-bottom: 0px !important; }
    .base-container__row--fill-height { margin: 0px !important; }

    // Start of Main Body

    .el-card {
      box-shadow: none !important;
      border-radius: 0px  !important;
      border: none !important;
      background-color: #FFFF;
    }

    .base-card {
      overflow: initial !important;
      page-break-inside: auto !important;
      box-shadow: none !important;
      z-index: 1;
      height: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;

        &__header {

          &--plain {
            background-color: #fff !important;
            padding-bottom: 0px !important;
            padding: 10px 0px 0px 0px !important;
            // padding: 10px 30px 0px 30px !important;
          }

          &--colored {
            background-color: #409EFF !important;
            padding: 30px !important;
          }

          display: flex;
          height: auto !important;
          min-height: 0 !important;
          flex-basis: auto !important;
          justify-content: flex-start !important;
          overflow: auto !important;

          &__title {
            min-height: 70px !important;
            height: calc(70px + 15%) !important;

            &__row {
              margin-bottom: 2.5px !important;
              padding-bottom: 0 !important;
            }
          }
        }

        &__description {
          // &--colored { padding: 20px 30px !important; }
          &--colored { padding: 20px 0px !important; }
          &--plain { padding: 20px 0px 0px 0px !important; }
        }

        &__breakdown {
          page-break-inside: avoid !important;

          &--plain {
            background-color: #fff !important;
            padding: 20px 0px 20px 0px !important;
          }

          &--colored {
            background-color: #fff !important;
            padding: 20px 30px 20px 30px !important;
          }
        }

        &__signing {

          &__publisher-area {
            padding-right: 80px;
          }

          &__approver-area {
            padding-left: 60px;
          }
        }
    }

    .col { overflow: initial !important; }

    .base-card__sticky {
      height: 0;
      width: 0;
      display: none;
      visibility: hidden;
    }

    .el-card__body { width: 100%; }

    // Table Page Breaking
    .water-mark { page-break-inside: auto;}

    table { page-break-inside:auto !important; }

    tr td {
      page-break-inside:avoid !important;
      page-break-after:auto !important;
    }

    thead { display:table-header-group !important; }

    .td {
      page-break-after:auto !important;

      &--right { text-align: right; }
    }

    .th {
      vertical-align: text-top;
      text-align: left;
      padding: 0.5rem 0;
      border-bottom: 1px rgba(0, 0, 0, 0.45) solid !important;
      padding: 1px 3px !important;

      &--right {  text-align: right; }
    }
  }
  // End of Print Query
</style>

<style lang="scss" scoped>

.water-mark {
  text-align: right;
  margin-right: 80px;

  img {
    width: 400px;
    height: auto;
  }
}

.el-popover {
  background-color: #000;
  border: none;
  border-radius: 0px;
}

.options {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
  margin-top: 4px;
}

.isActive { background-color: #fff; }

.body-row {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.col {
  flex-grow: 1;
  height: 0;
  overflow: auto;
}

.base-card {
  font-family: Poppins, Helvetica, sans-serif !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__header {

    display: flex;
    background-color: #409EFF;
    height: calc(50% + 40px);
    min-height: 20vh;
    padding: 5% 7%;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;

    &--plain { background-color: #fff;
    }

    &--colored { background-color: #409EFF; }

    &__title {
      display: flex;
      justify-content: space-between;
      height: 70%;
      width: 100%;

      &__row {
        display: flex;
        flex: 1;
        height: 100%;
        margin-bottom: 1.25rem;

        &__transaction-type {
          height: 100%;
          position: relative;
          top: -5px;
        }

        &__subcontent {
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: right;
        }
      }
    }

    &__details {
      padding-top: 0.75rem;

      &__row {

        &__content {

          padding-right: 1rem;
          display: flex;
          flex-direction: column;

          &:nth-of-type(3) {
            padding-right: 0 !important;
          }

          .vertical-spacing {
            padding-bottom: 0.15rem;
          }

          .secondary-vertical-spacing {
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
          }

          .horizontal-spacing {
            padding-right: 0.35rem;
          }

          &__data {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px #fff solid;
            margin-top: 0.3rem;

            &:first-of-type {
              margin-top: 0px;
            }
          }

          &__contacts { display: flex; }
        }
      }
    }
  }

  &__description {

    display: flex;
    box-sizing: border-box;
    padding: 3% 7%;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;

    .table-footer {
      z-index: 1;
      text-align: center;
      padding-top: 5px;
    }
  }

  &__breakdown {

    &--plain { background-color: #fff !important; }

    &--colored { background-color:rgba(54,153,255,.04) !important; }

    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    padding: 3% 7%;
    flex: 1;
    width: 100%;
    background-color: #F3F6F9;

    &__desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      &__header {
        color: #3F4254;
        margin-bottom: 0.8rem;
      }

      &__accounts {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        padding-bottom: 0.2rem;
        border-bottom: 1px #000 solid;

      }
    }

    &__summary {
      display: flex;
      width: 100%;
      flex-direction: column;
      text-align: right;
      justify-content: center;
    }
  }

  &__signing {
    padding: 4% 0%;

    &__publisher-area {
      text-align: center;
      display: flex;
      flex-direction: column;
    }

     &__approver-area {
       justify-content: center;
       display: flex;
     }
  }

  &__sticky {
    padding-top: 20px;
    display: flex;
    position: fixed;
    width: 100%;
    flex-direction: row;
    z-index: 1;

    .el-popover { padding-left: 0px !important; }

    &__preview { display: flex; }

    &__print { display: flex; }
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      type: 'dark',
      toggler: false,
      text: 'Colored',
      colorMode: 'plain',
      plain: true
    }
  },

  methods: {
    print () {
      window.print()
    },

    colorPlain () {
      this.type = 'primary'
      this.text = 'Colored'
      this.colorMode = 'plain'
      this.plain = true
    },

    colorDefault () {
      this.type = 'dark'
      this.text = 'Grayscale'
      this.colorMode = 'colored'
      this.plain = false
    },

    switchColor () {
      if (this.colorMode === 'colored') {
        setTimeout(this.colorPlain(), 100)
      } else if (this.colorMode === 'plain') {
        setTimeout(this.colorDefault(), 100)
      }
    },

    back () {
      this.$router.back()
    }
  },

  props: {
    buttonLabel: String,
    isActive: String,
    isPlain: Boolean,
    title: String
  },

  computed: {
    ...mapGetters([
      'breadcrumbs',
      'pageTitle'
    ])
  }
}
</script>
