<template>
  <gf-card :title="title" :subtitle="subtitle">
      <div class="mini-widget__list mb-5" v-for="item in items" :key="item.id">
        <div class="mini-widget__list__content pb-4 pt-4 rounded" :class="background(item.type)">
          <div class="mini-widget__list__content__item pl-4">
            <span class="font-size-sm font-weight-bolder" :class="[light ? 'text-dark' : 'text-white']">{{ item.header }}</span>
            <span class="font-size-xs font-weight-bold" :class="[light ? 'text-muted' : 'text-white']">{{ item.subheader }}</span>
          </div>
          <span class="font-size-sm font-weight-bolder pr-4" :class="[light ? 'text-' + item.type : 'text-white']">+{{ item.quantity }}</span>
        </div>
      </div>
  </gf-card>
</template>

<style lang="scss" scoped>
.mini-widget__list {
  display: flex;
  flex: 1;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
</style>

<script>
export default {
  data () {
    return {
      color: ''
    }
  },
  props: {
    items: Array,
    title: String,
    subtitle: String,
    light: Boolean
  },
  methods: {
    background (color) {
      let className = 'bg'
      if (this.light) className += '-light'
      if (color) className += `-${color}`
      return className
    }
  }
}
</script>
