<template>
  <el-popover
    :placement="placement || 'right'"
    :width="width"
    trigger="hover"
    class="popover-info"
  >
    <div class="popover-info__header">
      <slot name="header"></slot>
      <div class="icon-container">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="popover-info__subheader">
      <slot name="subheader"></slot>
    </div>

    <div class="popover-info__content">
      <slot name="content"></slot>
    </div>

    <div slot="reference" class="popover-info__reference">
      <slot name="element"></slot>
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    placement: String,
    width: String
  }
}
</script>

<style lang="scss">
.popover-info {
  width: 100%;

  &__subheader {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    font-size: 13px;
  }

  &__header {
    padding-bottom: 0px !important;
  }

  &__header, &__subheader {

    background-color: #f3f3f3;
    display: inline-block;
    margin-left: -10px;
    margin-top: -10px;
    padding: 10px;
    position: relative;
    width: 100%;

    a {
      box-sizing: border-box;
      color: #0a0a0a;
      text-align: left;
      display: inline-block;
      position: relative;
      padding-right: 15px;
      word-break: break-word;
      width: 100%;
      z-index: 2;
    }

    .icon-container {
      box-sizing: border-box;
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      left: 0;
      padding-right: 10px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &__content {
    padding-top: 2.5px;

    strong {
      font-weight: 500;
    }
  }

  &__reference {
    display: inline;
  }
}
</style>
