<template>
  <div class="helper">
    <div class="helper__title">{{ title }}</div>
    <div class="helper__content">{{ content }}</div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/components/_variables.bootstrap.scss';

.helper {
  background: $gray-100;
  border-radius: 7.5px;
  padding: 20px;

  &__title {
    color: $gray-800;
    font-weight: $font-weight-bold;
  }

  &__content {
    margin-top: 10px;
    font-size: 14px;
    color: $gray-800;
    font-weight: $font-weight-normal;
  }
}
</style>

<script>
export default {
  props: {
    title: String,
    content: String
  }
}
</script>
