<template>
  <ul class="menu-nav">
    <li v-for="(item, index) in menu" :key="item.id" :class="item.type">
      <span v-if="item.type === 'section' && menu[index + 1] && menu[index + 1].type !== 'section'">{{ item.name }}</span>
      <router-link v-else-if="item.type === 'link'" :to="item.link" active-class="active-link"><i :class="item.icon"></i>{{ item.name }}</router-link>
      <div class="sub-menu" v-else-if="item.type === 'menu' && item.children.length > 0" @click="item.show = !item.show" :class="item.show ? 'active spin': ''">
        <span><i :class="item.icon"></i> {{ item.name }}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <transition name="expand" @enter="enter" @leave="leave">
        <div class="sub-nav" v-if="item.children && item.children.length > 0 && item.show">
          <BaseMenuItem :menu="item.children"></BaseMenuItem>
        </div>
      </transition>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.expand-enter-active, .expand-leave-active {
  transition: height .5s ease-in-out;
  overflow: hidden;
}

.menu-nav {
  padding: 0;
  margin: 0;
  list-style: none;

  .section {
    padding: 5px 10px;
    text-overflow: ellipsis;

    span {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .menu, .link {
    display: flex;
    flex-direction: column;

    & > a {
      padding: 10px 20px;
    }

    a {
      display: flex;
      flex-grow: 1;
      transition: all .3s;
      align-items: center;
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      i {
        margin-right: 8px;
        font-size: 18px;
      }

      &:hover {
        background: #242849;
        color: rgba(255, 255, 255, 0.774);
      }
    }

    .sub-menu {
      display: flex;
      transition: all .3s;
      align-items: center;
      cursor: pointer;
      padding: 10px 20px;

      span {
        display: flex;
        align-items: center;
        flex-grow: 1;

        i {
          margin-right: 8px;
          font-size: 18px;
        }
      }

      .el-icon-arrow-right {
        transition: transform .3s;
      }

      &:hover {
        background: #242849;
        color: rgba(255, 255, 255, 0.774);
      }
    }

    .sub-nav a {
      padding-left: 40px !important;
    }
  }

  .active {
    background: #242849;
    color: #fff !important;
  }

  .active-link {
    color: #409EFF !important;
  }

  .spin {
    .el-icon-arrow-right {
      transform: rotate(90deg);
    }
  }
}
</style>

<script>
export default {
  props: {
    menu: Array
  },
  methods: {
    enter (el) {
      const height = getComputedStyle(el).height
      el.style.height = 0

      setTimeout(() => {
        el.style.height = height
      })
    },
    leave (el) {
      el.style.height = 0
    }
  }
}
</script>
