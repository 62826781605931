<template>
  <button type="button" class="btn" :class="[type && !light ? 'btn-' + type : '', type && light ? 'btn-light-' + type : '', size ? 'btn-' + size : '']" @click="handleClick" :disabled="disabled">
    <div class="btn__content">
      <slot name="icon" v-if="$slots['icon']"></slot>
      <slot></slot>
    </div>
  </button>
</template>

<style lang="scss" scoped>
.btn {
  min-width: 70px;

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<script>
export default {
  props: {
    type: String,
    size: String,
    disabled: Boolean,
    loading: Boolean,
    light: Boolean
  },
  methods: {
    handleClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>
