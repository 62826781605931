import Vue from 'vue'
import _ from 'lodash'
import Numeral from 'numeral'
import { DateTime } from 'luxon'

import { SET_BREADCRUMB } from '@/store/breadcrumbs.module'

const plugin = {
  install() {
    Vue.prototype.$_ = _
    Vue.prototype.$SET_BREADCRUMB = SET_BREADCRUMB

    Vue.prototype.$StringFormat = function(string, length = 18) {
      if (!string) return ''
      return string.length > length
        ? string.substring(0, length) + ' . . .'
        : string
    }

    Vue.prototype.$DateISOFormat = function(dateString) {
      return DateTime.fromISO(dateString).toISODate()
    }

    Vue.prototype.$DateFormat = function(dateString) {
      return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED)
    }

    Vue.prototype.$GetTime = function(dateString) {
      return DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE)
    }

    Vue.prototype.$Sanitize = function(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === '') delete obj[key]
      }
    }

    Vue.prototype.$NumberFormat = function(value) {
      return Numeral(value).format('0,0,0,0.00')
    }

    Vue.prototype.$ClearField = function(form) {
      this.$refs[form].resetFields()
    }

    Vue.prototype.$DateTime = DateTime

    Vue.prototype.$Numeral = Numeral

    Vue.prototype.$Error = function(error) {
      let message = ''
      if (error.response) {
        message =
          error.response.data?.error || error.response.data?.errors[0].message
      } else {
        message = error.message
      }

      return this.$notify.error({
        title: 'Error',
        message: message || 'Oops! Something went wrong.'
      })
    }

    Vue.prototype.$GetPermission = function(permission) {
      if (
        JSON.parse(localStorage.getItem('user')).permissions.find(
          perm => perm.code === permission
        )
      ) {
        return true
      }
      return false
    }

    Vue.prototype.$IntToRoman = function(num) {
      const romanNumeralMap = [
        { value: 1000, numeral: 'M' },
        { value: 900, numeral: 'CM' },
        { value: 500, numeral: 'D' },
        { value: 400, numeral: 'CD' },
        { value: 100, numeral: 'C' },
        { value: 90, numeral: 'XC' },
        { value: 50, numeral: 'L' },
        { value: 40, numeral: 'XL' },
        { value: 10, numeral: 'X' },
        { value: 9, numeral: 'IX' },
        { value: 5, numeral: 'V' },
        { value: 4, numeral: 'IV' },
        { value: 1, numeral: 'I' }
      ]

      let result = ''

      for (let i = 0; i < romanNumeralMap.length; i++) {
        while (num >= romanNumeralMap[i].value) {
          result += romanNumeralMap[i].numeral
          num -= romanNumeralMap[i].value
        }
      }

      return result
    }
  }
}

Vue.use(plugin)
