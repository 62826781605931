import { render, staticRenderFns } from "./gf-label.vue?vue&type=template&id=50d0889e&scoped=true&"
import script from "./gf-label.vue?vue&type=script&lang=js&"
export * from "./gf-label.vue?vue&type=script&lang=js&"
import style0 from "./gf-label.vue?vue&type=style&index=0&id=50d0889e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d0889e",
  null
  
)

export default component.exports