export const TOGGLE_SIDEBAR = 'toggleSidebar'
export const MINIMIZE_SIDEBAR = 'minimizeSidebar'

export default {
  state: {
    show: false,
    minimize: false
  },
  getters: {
    show (state) {
      return state.show
    },
    minimize (state) {
      return state.minimize
    }
  },
  actions: {
    [TOGGLE_SIDEBAR] (state, payload) {
      state.commit(TOGGLE_SIDEBAR, payload)
    },
    [MINIMIZE_SIDEBAR] (state, payload) {
      state.commit(MINIMIZE_SIDEBAR, payload)
    }
  },
  mutations: {
    [TOGGLE_SIDEBAR] (state, payload) {
      state.show = payload
    },
    [MINIMIZE_SIDEBAR] (state, payload) {
      state.minimize = payload
    }
  }
}
