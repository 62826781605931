<template>
  <div class="menu">
    <BaseMenuItem :menu="data"></BaseMenuItem>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
</style>

<script>
export default {
  props: {
    data: Array
  }
}
</script>
