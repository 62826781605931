<template>
  <div class="table">
    <transition name="slide" @enter="enterSlide" @leave="leaveSlide">
      <div class="select" v-if="selectionCommand && selection.length > 0">
        <el-dropdown trigger="click" @command="command" placement="bottom-start">
          <el-button size="small">
            Bulk Actions ({{ selection.length }} selected)<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="cmd in selectionCommand" :key="cmd.command" :command="cmd.command" :divided="cmd.divided">{{ cmd.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </transition>
    <div v-if="columnFilter" class="column-filter">
      <el-dropdown trigger="click" :hide-on-click="false" @command="filter">
        <div class="menu"><i class="el-icon-setting"></i></div>
        <el-dropdown-menu slot="dropdown">
          <span style="padding: 0 20px; font-size: 15px;">Show Columns</span>
          <el-dropdown-item v-for="field in columnFilter" :key="field.label">
            <el-checkbox v-model="field.show">{{ field.label }}</el-checkbox>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table v-if="scrollable" ref="table" :data="data" style="width: 100%; height: 100%" height="100%" :show-summary="showSummary" :summary-method="summaryMethod" @selection-change="selectionChange" @row-click="rowClick">
      <slot></slot>
    </el-table>
    <el-table v-else ref="table" :data="data" :show-summary="showSummary" :summary-method="summaryMethod" @selection-change="selectionChange" @row-click="rowClick">
      <slot></slot>
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/components/_variables.bootstrap.scss';

.table {
  display: flex;
  flex-grow: 1;
  height: 100%;
  position: relative;

  .column-filter {
    position: absolute;
    right: 0px;
    margin-top: 3px;
    z-index: 1;

    .menu {
      cursor: pointer;
      padding: 6px;
      border-radius: 5.5px;
      background: $primary-light;
      display: flex;
      align-items: center;
      transition: .3s all;

      &:hover {
        background: $primary;

        & > i {
          color: #fff;
        }
      }

      i {
        transition: .2s all;
        font-size: 20px;
        color: $primary;
      }
    }
  }

  .select {
    position: absolute;
    left: 30px;
    margin-top: 2px;
    z-index: 2;
  }
}
</style>

<script>
export default {
  props: {
    data: Array,
    selectionCommand: Array,
    columnFilter: Array,
    scrollable: {
      type: Boolean,
      default: true
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    summaryMethod: Function
  },
  data () {
    return {
      selection: []
    }
  },
  methods: {
    rowClick (row, event) {
      if (!event) return
      if (event.className === 'el-table-column--selection') return
      if (event.property === 'action') return
      if (event.property === 'status') return
      this.$emit('row-click', row)
    },
    selectionChange (selection) {
      this.selection = selection
      this.$emit('selection-change', selection)
    },
    command (cmd) {
      this.$emit('command', {
        cmd,
        selection: this.selection
      })
    },
    filter () {
      setTimeout(() => this.$refs.table.doLayout(), 10)
    },

    // Transition
    enterSlide (el) {
      const width = getComputedStyle(el).width
      el.style.width = 0

      setTimeout(() => {
        el.style.width = width
      })
    },
    leaveSlide (el) {
      const width = getComputedStyle(el).width
      el.style.width = width

      setTimeout(() => {
        el.style.width = 0
      })
    }
  }
}
</script>
