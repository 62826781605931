<template>
  <el-dropdown class="menu" trigger="click" @visible-change="handleDropdown($event)">
    <div class="menu-wrapper">
      <div class="menu-box" :class="active">
        <slot name="menu"></slot>
      </div>
    </div>
    <el-dropdown-menu slot="dropdown" class="content-wrapper">
      <div class="content">
        <div class="header">
          <slot name="header"></slot>
        </div>
        <div class="menu-list">
          <slot name="menu-list"></slot>
        </div>
        <div class="footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<style lang="scss" scoped>
  .menu {
    display: flex;
    align-items: stretch;

    .menu-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;

      .menu-box {
        display: flex;
        align-items: center;
        padding: 6px;
        border-radius: 3px;
        transition: all .3s;

        &:hover {
          background: rgba(0, 0, 0, .05);
        }
      }

      .active {
        background: rgba(0, 0, 0, .05);
      }
    }
  }

  .el-dropdown-menu {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0;
    border: none;

    .header {
      display: flex;
      align-items: center;
      min-height: 80px;
      padding: 20px;
      background: url('../../src/assets/bg-1.jpg');
      min-width: 300px;
      color: #fff;
    }
  }
</style>

<script>
export default {
  data () {
    return {
      active: ''
    }
  },
  methods: {
    handleDropdown (event) {
      this.active = event ? 'active' : ''
    }
  }
}
</script>
