import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/login/Login')
  },
  {
    path: '/import',
    name: 'import',
    component: () => import('@/views/pages/bulkmanage/Import'),
    children: [
      {
        path: 'company',
        name: 'company-import',
        component: () => import('@/views/pages/bulkmanage/import/Company')
      },
      {
        path: 'company-upload',
        name: 'company-upload',
        component: () => import('@/views/pages/bulkmanage/import/CompanyUpload')
      },
      {
        path: 'company-generate',
        name: 'company-generate',
        component: () =>
          import('@/views/pages/bulkmanage/import/CompanyGenerate')
      },
      {
        path: 'inventory',
        name: 'inventory-import',
        component: () => import('@/views/pages/bulkmanage/import/Inventory')
      },
      {
        path: 'inventory-upload',
        name: 'inventory-upload',
        component: () =>
          import('@/views/pages/bulkmanage/import/InventoryUpload')
      },
      {
        path: 'inventory-generate',
        name: 'inventory-generate',
        component: () =>
          import('@/views/pages/bulkmanage/import/InventoryGenerate')
      }
    ]
  },
  {
    path: '/',
    redirect: { name: 'dashboard' },
    component: () => import('@/views/theme/Body'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/Dashboard')
      },
      {
        path: 'user-profile',
        name: 'user-profile',
        redirect: { name: 'user-profile-personal' },
        component: () => import('@/views/pages/user-profile/UserProfile'),
        children: [
          {
            path: '',
            component: () => import('@/views/pages/user-profile/View'),
            redirect: { name: 'user-profile-personal' },
            children: [
              {
                path: 'personal',
                name: 'user-profile-personal',
                component: () =>
                  import(
                    '@/views/pages/user-profile/view-tabs/PersonalInformation'
                  )
              },
              {
                path: 'account',
                name: 'user-profile-account',
                component: () =>
                  import(
                    '@/views/pages/user-profile/view-tabs/AccountInformation'
                  )
              },
              {
                path: 'password',
                name: 'user-profile-password',
                component: () =>
                  import('@/views/pages/user-profile/view-tabs/ChangePassword')
              }
            ]
          }
        ]
      },
      {
        path: 'inventory',
        redirect: { name: 'products' },
        component: () => import('@/views/pages/inventory/Inventory'),
        children: [
          {
            path: 'products',
            component: () => import('@/views/pages/inventory/products/Product'),
            children: [
              {
                path: '',
                name: 'product-list',
                component: () => import('@/views/pages/inventory/products/List')
              },
              {
                path: 'new',
                name: 'product-create',
                component: () => import('@/views/pages/inventory/products/Form')
              },
              {
                path: ':id',
                component: () =>
                  import('@/views/pages/inventory/products/View'),
                children: [
                  {
                    path: '',
                    name: 'product-detail',
                    component: () =>
                      import('@/views/pages/inventory/products/views/Detail')
                  },
                  {
                    path: 'variant',
                    name: 'product-variants',
                    component: () =>
                      import('@/views/pages/inventory/products/views/Variants')
                  },
                  {
                    path: 'material',
                    name: 'product-materials',
                    component: () =>
                      import('@/views/pages/inventory/products/views/Materials')
                  }
                ]
              },
              {
                path: ':id/variant/new',
                name: 'product-variant-create',
                component: () =>
                  import('@/views/pages/inventory/products/variant/Form.vue')
              },
              {
                path: ':id/variant/:vid',
                component: () =>
                  import('@/views/pages/inventory/products/variant/View.vue'),
                children: [
                  {
                    path: '',
                    name: 'product-variant-detail',
                    component: () =>
                      import(
                        '@/views/pages/inventory/products/variant/views/Detail.vue'
                      )
                  },
                  {
                    path: 'pack',
                    name: 'product-variant-pack',
                    component: () =>
                      import(
                        '@/views/pages/inventory/products/variant/views/Pack.vue'
                      )
                  },
                  {
                    path: 'stock-location',
                    name: 'product-variant-stock',
                    component: () =>
                      import(
                        '@/views/pages/inventory/products/variant/views/StockLocation.vue'
                      )
                  },
                  {
                    path: 'price-history',
                    name: 'product-variant-price',
                    component: () =>
                      import(
                        '@/views/pages/inventory/products/variant/views/PriceHistory.vue'
                      )
                  },
                  {
                    path: 'stock-activity',
                    name: 'product-variant-activity',
                    component: () =>
                      import(
                        '@/views/pages/inventory/products/variant/views/StockActivity.vue'
                      )
                  }
                ]
              },
              {
                path: ':id/variant/:vid/pack/new',
                name: 'product-variant-pack-create',
                component: () =>
                  import('@/views/pages/inventory/products/variant/pack/Form'),
                meta: {
                  mode: 'create'
                }
              },
              {
                path: ':id/variant/:vid/pack/:pid',
                name: 'product-variant-pack-view',
                component: () =>
                  import('@/views/pages/inventory/products/variant/pack/Form'),
                meta: {
                  mode: 'update'
                }
              }
            ]
          },
          {
            path: 'variants',
            component: () => import('@/views/pages/inventory/variants/Variant'),
            children: [
              {
                path: '',
                name: 'variant-list',
                component: () => import('@/views/pages/inventory/variants/List')
              },
              {
                path: 'print',
                name: 'variant-print',
                component: () =>
                  import('@/views/pages/inventory/variants/Print')
              }
            ]
          },
          {
            path: 'price-lists',
            component: () =>
              import('@/views/pages/inventory/price-lists/PriceList'),
            children: [
              {
                path: '',
                name: 'pricelist-list',
                component: () =>
                  import('@/views/pages/inventory/price-lists/List')
              },
              {
                path: 'new',
                name: 'pricelist-new',
                component: () =>
                  import('@/views/pages/inventory/price-lists/Form')
              },
              {
                path: ':id',
                component: () =>
                  import('@/views/pages/inventory/price-lists/View'),
                children: [
                  {
                    path: '',
                    name: 'pricelist-detail',
                    component: () =>
                      import(
                        '@/views/pages/inventory/price-lists/view-tabs/Details'
                      )
                  },
                  {
                    path: 'pricing',
                    name: 'pricelist-pricing',
                    component: () =>
                      import(
                        '@/views/pages/inventory/price-lists/view-tabs/Pricing'
                      )
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'sales-order',
        redirect: '/sales-order/orders',
        component: () => import('@/views/pages/sales-order/SalesOrder'),
        children: [
          {
            path: 'orders',
            component: () => import('@/views/pages/sales-order/orders/Order'),
            children: [
              {
                path: ':id/print-order',
                name: 'print-order',
                component: () =>
                  import('@/views/pages/sales-order/orders/PrintSO')
              },
              {
                path: ':id/invoices/:iid/print',
                name: 'invoice-print',
                component: () =>
                  import('@/views/pages/sales-order/orders/invoice/Print')
              },
              {
                path: ':id/shipments/:sid/print',
                name: 'shipment-print-pack',
                component: () =>
                  import('@/views/pages/sales-order/orders/shipment/PrintPack')
              },
              {
                path: '',
                component: () =>
                  import('@/views/pages/sales-order/orders/Base'),
                children: [
                  {
                    path: '',
                    name: 'order-list',
                    component: () =>
                      import('@/views/pages/sales-order/orders/List')
                  },
                  {
                    path: 'new',
                    name: 'order-new',
                    component: () =>
                      import('@/views/pages/sales-order/orders/Form'),
                    meta: { mode: 'create' }
                  },
                  {
                    path: ':id',
                    name: 'order-view',
                    component: () =>
                      import('@/views/pages/sales-order/orders/View')
                  },
                  {
                    path: ':id/edit',
                    name: 'order-edit',
                    component: () =>
                      import('@/views/pages/sales-order/orders/Form'),
                    meta: { mode: 'update' }
                  },
                  {
                    path: ':id/invoices',
                    name: 'invoice-list',
                    component: () =>
                      import('@/views/pages/sales-order/orders/invoice/List')
                  },
                  {
                    path: ':id/invoices/new',
                    name: 'invoice-new',
                    component: () =>
                      import('@/views/pages/sales-order/orders/invoice/Form'),
                    meta: { mode: 'create' }
                  },
                  {
                    path: ':id/invoices/:iid',
                    name: 'invoice-view',
                    component: () =>
                      import('@/views/pages/sales-order/orders/invoice/View')
                  },
                  {
                    path: ':id/invoices/:iid/edit',
                    name: 'invoice-edit',
                    component: () =>
                      import('@/views/pages/sales-order/orders/invoice/Form'),
                    meta: { mode: 'update' }
                  },
                  {
                    path: ':id/shipments',
                    name: 'shipment-list',
                    component: () =>
                      import('@/views/pages/sales-order/orders/shipment/List')
                  },
                  {
                    path: ':id/shipments/new',
                    name: 'shipment-new',
                    component: () =>
                      import('@/views/pages/sales-order/orders/shipment/Form'),
                    meta: { mode: 'create' }
                  },
                  {
                    path: ':id/shipments/:sid',
                    name: 'shipment-view',
                    component: () =>
                      import('@/views/pages/sales-order/orders/shipment/View')
                  },
                  {
                    path: ':id/shipments/:sid/edit',
                    name: 'shipment-edit',
                    component: () =>
                      import('@/views/pages/sales-order/orders/shipment/Form'),
                    meta: { mode: 'update' }
                  }
                ]
              }
            ]
          },
          {
            path: 'invoices',
            component: () =>
              import('@/views/pages/sales-order/invoices/Invoice'),
            children: [
              {
                path: '',
                name: 'invoices-list',
                component: () =>
                  import('@/views/pages/sales-order/invoices/List')
              }
            ]
          },
          {
            path: 'shipments',
            component: () =>
              import('@/views/pages/sales-order/shipments/Shipment'),
            children: [
              {
                path: '',
                name: 'shipments-list',
                component: () =>
                  import('@/views/pages/sales-order/shipments/List')
              }
            ]
          }
        ]
      },
      {
        path: 'stock-control',
        redirect: { name: 'purchase-orders-list' },
        component: () => import('@/views/pages/stock-control/StockControl'),
        children: [
          {
            path: 'purchase-orders',
            component: () =>
              import(
                '@/views/pages/stock-control/purchase-orders/PurchaseOrder'
              ),
            children: [
              {
                path: '',
                name: 'purchase-order-list',
                component: () =>
                  import('@/views/pages/stock-control/purchase-orders/List')
              },
              {
                path: ':id/print-po',
                name: 'purchase-order-print',
                component: () =>
                  import('@/views/pages/stock-control/purchase-orders/PrintPO')
              },
              {
                path: 'new',
                name: 'purchase-order-new',
                component: () =>
                  import('@/views/pages/stock-control/purchase-orders/Form'),
                meta: { mode: 'create' }
              },
              {
                path: ':id',
                component: () =>
                  import('@/views/pages/stock-control/purchase-orders/View'),
                children: [
                  {
                    path: '',
                    name: 'purchase-order-detail',
                    component: () =>
                      import(
                        '@/views/pages/stock-control/purchase-orders/View-tabs/Detail'
                      )
                  },
                  {
                    path: 'history',
                    name: 'purchase-order-history',
                    component: () =>
                      import(
                        '@/views/pages/stock-control/purchase-orders/View-tabs/History'
                      )
                  }
                ]
              },
              {
                path: ':id/edit',
                name: 'purchase-order-edit',
                component: () =>
                  import('@/views/pages/stock-control/purchase-orders/Form'),
                meta: { mode: 'update' }
              }
            ]
          },
          {
            path: 'stock-adjustments',
            component: () =>
              import(
                '@/views/pages/stock-control/stock-adjustments/StockAdjustment'
              ),
            children: [
              {
                path: '',
                name: 'stock-adjustment-list',
                component: () =>
                  import('@/views/pages/stock-control/stock-adjustments/List')
              },
              {
                path: 'new',
                name: 'stock-adjustment-new',
                component: () =>
                  import('@/views/pages/stock-control/stock-adjustments/Form')
              },
              {
                path: ':id', // temporary
                component: () =>
                  import('@/views/pages/stock-control/stock-adjustments/View'),
                children: [
                  {
                    path: '',
                    name: 'stock-adjustment-detail',
                    component: () =>
                      import(
                        '@/views/pages/stock-control/stock-adjustments/View-tabs/Detail'
                      )
                  },
                  {
                    path: 'history',
                    name: 'stock-adjustment-history',
                    component: () =>
                      import(
                        '@/views/pages/stock-control/stock-adjustments/View-tabs/History'
                      )
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'transactions',
        component: () => import('@/views/pages/transaction/List'),
        name: 'transaction-list'
      },
      {
        path: 'transactions/:id',
        name: 'transaction-view',
        component: () => import('@/views/pages/transaction/View')
      },
      {
        path: 'relationship',
        component: () => import('@/views/pages/relationship/Relationship'),
        children: [
          {
            path: 'company',
            component: () =>
              import('@/views/pages/relationship/company/Company'),
            children: [
              {
                path: '',
                name: 'company-list',
                component: () =>
                  import('@/views/pages/relationship/company/List')
              },
              {
                path: 'new/:type',
                name: 'company-new',
                component: () =>
                  import('@/views/pages/relationship/company/Form'),
                meta: { mode: 'create' }
              },
              {
                path: ':id',
                name: 'company-view',
                redirect: { name: 'company-contacts' },
                component: () =>
                  import('@/views/pages/relationship/company/View'),
                children: [
                  {
                    path: 'contacts',
                    name: 'company-contacts',
                    component: () =>
                      import(
                        '@/views/pages/relationship/company/view-tabs/Contact'
                      )
                  },
                  {
                    path: 'addresses',
                    name: 'company-addresses',
                    component: () =>
                      import(
                        '@/views/pages/relationship/company/view-tabs/Addresses'
                      )
                  },
                  {
                    path: 'materials',
                    name: 'company-materials',
                    component: () =>
                      import(
                        '@/views/pages/relationship/company/view-tabs/Materials'
                      )
                  }
                ]
              },
              {
                path: ':id/edit',
                name: 'company-edit',
                component: () =>
                  import('@/views/pages/relationship/company/Form'),
                meta: { mode: 'update' }
              },
              {
                path: ':id/materials/print',
                name: 'company-material-print',
                component: () =>
                  import('@/views/pages/relationship/company/view-tabs/Print')
              }
            ]
          },
          {
            path: 'customer-groups',
            component: () =>
              import(
                '@/views/pages/relationship/customer-groups/CustomerGroup'
              ),
            children: [
              {
                path: '',
                name: 'customer-group-list',
                component: () =>
                  import('@/views/pages/relationship/customer-groups/List')
              },
              {
                path: 'new',
                name: 'customer-group-new',
                component: () =>
                  import('@/views/pages/relationship/customer-groups/Form'),
                meta: { mode: 'create' }
              },
              {
                path: ':id',
                name: 'customer-group-view',
                component: () =>
                  import('@/views/pages/relationship/customer-groups/View')
              },
              {
                path: ':id/edit',
                name: 'customer-group-edit',
                component: () =>
                  import('@/views/pages/relationship/customer-groups/Form'),
                meta: { mode: 'update' }
              }
            ]
          }
        ]
      },
      {
        path: 'setting',
        redirect: { name: 'company-details' },
        component: () => import('@/views/pages/setting/Setting'),
        children: [
          {
            path: 'company-details',
            name: 'company-details',
            redirect: { name: 'users-list' },
            component: () =>
              import('@/views/pages/setting/company-details/CompanyDetails'),
            children: [
              {
                path: 'users',
                name: 'users-list',
                component: () =>
                  import('@/views/pages/setting/company-details/users/Users')
              },
              {
                path: 'groups',
                name: 'groups-list',
                component: () =>
                  import('@/views/pages/setting/company-details/groups/Groups')
              },
              {
                path: 'warehouse',
                name: 'warehouse-detail',
                component: () =>
                  import(
                    '@/views/pages/setting/company-details/warehouse/Detail'
                  )
              }
            ]
          },
          {
            path: 'configuration',
            name: 'configurations',
            redirect: { name: 'payment-terms-list' },
            component: () =>
              import('@/views/pages/setting/configuration/Configuration'),
            children: [
              {
                path: 'payment-terms',
                name: 'payment-terms-list',
                component: () =>
                  import('@/views/pages/setting/configuration/PaymentTerms')
              },
              {
                path: 'payment-methods',
                name: 'payment-methods-list',
                component: () =>
                  import('@/views/pages/setting/configuration/PaymentMethods')
              }
            ]
          },
          {
            path: 'company-details/users/add',
            name: 'users-add',
            component: () =>
              import('@/views/pages/setting/company-details/users/Form'),
            meta: {
              mode: 'create'
            }
          },
          {
            path: 'company-details/users/:id',
            name: 'users-view',
            component: () =>
              import('@/views/pages/setting/company-details/users/Form'),
            meta: {
              mode: 'update'
            }
          },
          {
            path: 'company-details/groups/add',
            name: 'groups-add',
            component: () =>
              import('@/views/pages/setting/company-details/groups/Form'),
            meta: {
              mode: 'create'
            }
          },
          {
            path: 'company-details/groups/:id',
            name: 'groups-view',
            component: () =>
              import('@/views/pages/setting/company-details/groups/Form'),
            meta: {
              mode: 'update'
            }
          }
        ]
      },
      {
        path: 'projects',
        component: () => import('@/views/pages/project/Project'),
        children: [
          {
            path: '',
            name: 'project-list',
            component: () => import('@/views/pages/project/List')
          },
          {
            path: 'create',
            name: 'project-create',
            component: () => import('@/views/pages/project/Form'),
            meta: {
              mode: 'create'
            }
          },
          {
            path: ':id',
            name: 'project-view',
            component: () => import('@/views/pages/project/View')
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: 'error404',
    component: () => import('@/views/pages/error/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (to.path === '/login' && loggedIn) {
    next('/dashboard')
  }

  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
