<template>
  <div class="pagination">
    <div class="left">
      <el-select v-model="pagination.page.size" @change="pageSizeChange" size="small">
        <el-option v-for="size in sizes" :key="size" :value="size" :label="`Show ${size} Items`"></el-option>
      </el-select>
    </div>
    <div class="right">
      <el-pagination background layout="prev, pager, next" :total="pagination.total" :current-page.sync="pagination.page.number" :page-size="pagination.page.size" @current-change="change">
      </el-pagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
}
</style>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          total: 0,
          page: {
            size: 25,
            number: 1
          }
        }
      }
    },
    sizes: {
      type: Array,
      default: () => [25, 50, 100]
    }
  },
  methods: {
    pageSizeChange () {
      this.pagination.page.number = 1
      this.$emit('change')
    },
    change () {
      this.$emit('change')
    }
  }
}
</script>
